import * as React from "react";
import Layout from "../../components/Layout";
import useScript from "../../hooks/useScript";
import "./countdowncube.css";

const QPScroll = (props) => {
  useScript(
    "//ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js",
    undefined,
    false
  );
  useScript("//static.cigari.co.uk/scripts/countdowncube.js", undefined, false);
  useScript(
    undefined,
    `
  setTimeout(() => {
    $('#counter').countdownCube({target: new Date( 'October 06, 2030 17:00:00' ), cubeSize: 100,  background: '#070E75' });
    $('.headerImage').append('<a href="https://github.com/oofaish/countdowncube" target="_blank" rel="noreferrer"><img class="forkMe pageSpecificElement" src="https://s3.amazonaws.com/github/ribbons/forkme_right_green_007200.png" alt="Fork me on GitHub"></a>');
  }
    , 500);
  `,
    false
  );
  return (
    <Layout>
      <div
        className="full-width-image-container margin-top-0 headerImage"
        style={{
          backgroundImage: `url('/img/becs-hill-climb-2022.jpeg')`,
        }}
      ></div>

      <div style={{ maxWidth: "700px", margin: "0 auto" }}>
        <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
          Countdown Cube
        </h2>
        <p>
          <b>Does anyone still use jQuery?</b> Well if you do, here is a jQuery
          countdown timer plugin that is in the form of a bunch of rotating 3D
          cubes. It uses CSS transitions to create the 3D rotating cube effects.
        </p>
        <div id="counter"></div>
        <p>
          <br></br>
          <br></br>
          Download the code{"  "}
          <a
            href="https://github.com/oofaish/countdownCube"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      </div>
    </Layout>
  );
};

export default QPScroll;
